import { defineStore } from 'pinia';
import type { EventDetailsReq, OrderDetailsReq, TicketDetailsReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';
import type { Order, Ticket, WemotooEvent } from '../types';

export const useTicketStore = defineStore({
	id: 'ticketStore',
	state: () => ({
		loading: false as boolean,
		order: null as Order | null,
		tickets: [] as Ticket[],
		event: null as WemotooEvent | null,
	}),
	actions: {
		async getTicketDetailByEventId(id?: number) {
			try {
				if (id == null) return;

				this.loading = true;

				const userStore = useUserStore();

				const eventReq: EventDetailsReq = {
					id: id.toString() ?? '',
					lat: userStore.location?.latitude.toString() ?? '0',
					lng: userStore.location?.longitude.toString() ?? '0',
					with_gallery: '1',
					with_sponsor: '1',
				};
				const ticketReq: TicketDetailsReq = { id };

				const $api = useNuxtApp().$api as IApiInstance;
				this.event = await $api.event.getEventDetailsById(eventReq);
				const tickets = await $api.event.getTicketDetailsByEventId(ticketReq);

				// const tickets = await new ApiService().fetchTicketDetailsApi(
				// 	{
				// 		id: id,
				// 	},
				// 	authStore.accessToken!,
				// );

				if (tickets != undefined && tickets) {
					this.tickets = tickets;
				}
			} catch (e) {
				console.error(e);
				// this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},
		async getTicketDetailByOrderId(id: number) {
			try {
				this.loading = true;

				const userStore = useUserStore();
				const $api = useNuxtApp().$api as IApiInstance;

				const orderDetailReq: OrderDetailsReq = { id };
				const response = await $api.order.getOrderDetails(orderDetailReq);
				// const response = await new ApiService().fetchOrderDetailsApi({ id }, authStore.accessToken!);

				if (response != undefined && response) {
					this.order = response;

					if (this.order?.event_id) {
						// const tickets = await new ApiService().fetchTicketDetailsApi(
						// 	{
						// 		id: this.order.event_id,
						// 	},
						// 	authStore.accessToken!,
						// );
						const ticketReq: TicketDetailsReq = { id: this.order.event_id };
						const tickets = await $api.event.getTicketDetailsByEventId(ticketReq);

						if (tickets != undefined && tickets) {
							this.tickets = tickets;
						}

						const eventReq: EventDetailsReq = {
							id: this.order.event_id.toString() ?? '',
							lat: userStore.location?.latitude.toString() ?? '0',
							lng: userStore.location?.longitude.toString() ?? '0',
							with_gallery: '1',
							with_sponsor: '1',
						};

						const eventDetail = await $api.event.getEventDetailsById(eventReq);
						if (eventDetail != undefined && eventDetail) {
							this.event = eventDetail;
						}
					}
				}
			} catch (e) {
				console.error(e);
				// this.error = JSON.stringify(e);
				return false;
			} finally {
				this.loading = false;
			}
		},
	},
});
